import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListProperties from "@components/properties/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "properties.getAll",
  query: 'crm_provider=property_uploader&crm_provider=franchisee&deleteAttempt=0'
}

const ListPropertiesPage = (props) => (
  <>
    <Seo title="Properties List" />
    <div className="main-wrapper">
      <Sidebar menuActive="properties" />
      <ListProperties
        addBtnText="Property"
        menu="Properties"
        parentPath="properties"
        pageType="List"
        searchBoxSuffixText="Property"
        additionalParams={AdditionalParams}
      />
    </div>
  </>
)

export default ListPropertiesPage;
